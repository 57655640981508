<template>
  <b-modal
    id="modal-zaloTemplate"
    ref="refModal"
    title="Mẫu tin Zalo"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên mẫu <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    :disabled="itemLocal.refId.length > 0"
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Mẫu chúc mừng sinh nhật"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" v-if="!itemLocal.refId">
            <b-form-group label="Nội dung gửi tin">
              <b-form-textarea
                ref="contentRef"
                v-model="itemLocal.content"
                rows="6"
              />
            </b-form-group>
          </b-col>

          <!-- Địa chỉ -->

          <!-- submit -->
        </b-row>
        <b-row v-if="!itemLocal.refId">
          <b-col cols="12">
            <media-view
              v-model="itemLocal.attachmentArray"
              :label="null"
              :initial-value="itemLocal.attachmentArray"
              :upload-imediately="true"
              @on-delete-uploaded-file="onDeleteAttachment"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="itemLocal.refId && itemLocal.previewUrl"
          v-for="(item, index) in itemLocal.paramObjects"
          :id="index"
          :key="item.name"
          ref="row"
        >
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              :name="`name-${index}`"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Mã (Zalo) <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    :disabled="true"
                    v-model="item.name"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              :name="`name-${index}`"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tag (ViCare) <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    v-model="item.vicareParam"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-1">
          <b-col cols="12">
            <span>Sử dụng tag</span>
          </b-col>
          
        </b-row>
        <b-row
          v-for="(item, index) in tags"
          :id="item.name"
          :key="item.name"
          ref="row"
        >
          <b-col cols="4">
            <b-form-group>
              <b-input-group>
                <b-form-input :disabled="true" v-model="item.value" trim />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  :disabled="true"
                  v-model="item.title"
                  trim
                />
              </b-input-group>
              
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
            <hr />
          </b-col> -->
        </b-row>
        
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import MediaView from "@/views/components/media/MediaView.vue";
import useZaloTemplateModal from "./useZaloTemplateModal";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    MediaView,
    BImg,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      tags,
      onSubmit,
      onOpen,
      contentRef,
      onSelectTag,
      onDeleteAttachment,
    } = useZaloTemplateModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      tags,
      resetItemLocal,
      resetModal,
      onSubmit,
      onOpen,
      contentRef,
      onSelectTag,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onDeleteAttachment,
    };
  },
};
</script>
./useZaloTemplateModal ./useZaloTemplateModal
