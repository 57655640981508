import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import zaloTemplateStoreModule from '../zaloTemplateStoreModule';

export default function useZaloTemplateGroupModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'zaloTemplate';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, zaloTemplateStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };
  const tags = ref([]);

  const getTags = () => {
    store.dispatch('zaloTemplate/getTags').then(response => {
      tags.value = response.data;
    }).catch(() => {
      toastification.showToastErrorWithMessage(`Không lấy được danh sách tags`);
    });
  };

  const onOpen = () => {
    resetModal();
    getTags();
  };

  const contentRef = ref(null);

  const onSelectTag = tag => {
    const currentPosition = contentRef.value.selectionStart;
    if (itemLocal.value.content && itemLocal.value.content.length > 0) {
      const textBefore = itemLocal.value.content.substring(0, contentRef.value.selectionStart);
      const textAfter = itemLocal.value.content.substring(contentRef.value.selectionStart, itemLocal.value.content.length);
      console.log(textBefore, textAfter);
      itemLocal.value = { ...itemLocal.value, content: `${textBefore}${tag.value}${textAfter}`.replaceAll("  ", "") };
      console.log(contentRef.value);
      contentRef.value.setSelectionRange(currentPosition, currentPosition);
    } else {
      itemLocal.value = { ...itemLocal.value, content: tag.value.replaceAll("  ", "") };
      contentRef.value.setSelectionRange(currentPosition, itemLocal.value.content.length);
    }
    contentRef.value.focus();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      content: itemLocal.value.content,
    };
    // attachments
    if (itemLocal.value.attachmentArray) {
      data.attachments = itemLocal.value.attachmentArray.map(object => object.location).join(";");
    }
    if (itemLocal.value.paramObjects && itemLocal.value.paramObjects.length > 0) {
      data.params = JSON.stringify(itemLocal.value.paramObjects)
    }
    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('zaloTemplate/updateItem', { data, id: itemLocal.value.id })
        .then(() => {
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('zaloTemplate/createItem', data)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  const onDeleteAttachment = () => { };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onOpen,
    tags,
    contentRef,
    onSelectTag,
    onDeleteAttachment,
  };
}
