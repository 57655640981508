<template>
  <div>
    <span
      v-if="label && label.length > 0"
    >
      {{ label }}
    </span>

    <b-row class="match-height mt-1">
      <b-col
        v-if="previewMode === false"
        md="2"
        cols="4"
      >
        <div
          class="image-container"
          :style="{width: `${width}`, height: `${height}`}"
        >
          <label
            :for="id"
            :style="{width: `${width}`, height: `${height}`}"
          >
            <div
              class="empty-img thumbnail"
            >
              <feather-icon
                :for="id"
                icon="PlusIcon"
                size="50"
                variant="primary"
              />
            </div>

          </label>
          <input
            :id="id"
            ref="input"
            type="file"
            :name="id"
            class="d-none"
            :accept="accept"
            multiple
            @change="onChangeFile"
          >
        </div>
      </b-col>
      <b-col
        v-for="(attachment, index) in attachments"
        :key="index"
        md="2"
        cols="4"
        :attachment="attachment"
      >
        <media-item
          :preview-mode="previewMode"
          :attachment="attachment"
          :target-object="targetObject"
          @on-delete="onDeleteItemAtIndex(index)"
          @on-uploaded="onUploadedAttachmentAtindex(index, $event)"
          @onMediaItemClicked="onMediaItemClicked(index)"
        />
      </b-col>
    </b-row>
    <!-- <media-modal v-if="viewMediaModal" /> -->
  </div>

</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue';
import MediaItem from './MediaItem.vue';

export default {
  components: {
    BRow,
    BCol,
    MediaItem,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    instruction: {
      type: String,
      default: 'Định dạng cho phép: PNG, JPG, JPEG. Dung lượng tối đa 10MB',
    },
    id: {
      type: String,
      default: 'file-input',
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    targetObject: {
      type: Object,
      default: () => ({
        id: null,
        type: null,
      }),
    },
    uploadImediately: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewMediaModal: false,
      attachments: this.initialValue,
      accept: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,image/png,image/jpeg,audio/mpeg,video/mp4,video/mpeg',
    };
  },

  watch: {
    initialValue(val) {
      this.attachments = val;
    },
  },
  methods: {
    onChangeFile({ target }) {
      const { files } = target;
      if (!files || !files.length) return;

      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (!file.name.toLowerCase().match(/.*\.(jpg|png|jpeg|pdf|doc|docx|xls|xlsx|mp3|mp4|mov|ppt|pptx)/g)) {
          //
        } else {
          const vm = this;
          const fr = new FileReader();

          const extension = file.name.split('.').pop();

          fr.onload = () => {
            const attachment = {
              file, src: fr.result, id: new Date().getTime(), extension, uploading: this.uploadImediately, uploadImediately: this.uploadImediately,
            };
            vm.attachments.push(attachment);
            vm.onChange();
          };
          fr.readAsDataURL(file);
        }
      }
    },
    onDeleteItemAtIndex(index) {
      if (this.attachments[index].location && this.attachments[index].location.length > 0) {
        this.$emit('on-delete-uploaded-file', this.attachments[index].id);
      }
      this.attachments = this.attachments.filter(obj => obj.id !== this.attachments[index].id);

      this.onChange();
    },
    onUploadedAttachmentAtindex(index, attachment) {
      this.attachments[index] = attachment;

      this.onChange();
    },
    onChange() {
      this.$emit('input', this.attachments);
    },
    onMediaItemClicked(index) {
      this.viewMediaModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.image-container {
    // width: fit-content;
    aspect-ratio: 1;

    .empty-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .thumbnail {
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 8px;
        aspect-ratio: 1;
    }

}
</style>
